import {computed, inject, Injectable, OnInit, signal} from '@angular/core';
import {TranslocoService} from "@jsverse/transloco";

export const languages: {
  code: string,
  language: string
}[] = [
  {
    code: 'es',
    language: 'Español (España)'
  },
  {
    code: 'en',
    language: 'English'
  }
]

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  transloco = inject(TranslocoService)
  activeLanguageLabel = signal(this.getCurrentLanguage().language);
  languages = signal(languages).asReadonly();

  constructor() {
    this.transloco.langChanges$.subscribe(() => {
      const activeLanguage = this.getCurrentLanguage();
      this.activeLanguageLabel.set(activeLanguage.language);
    })
  }

  private getCurrentLanguage(): {code: string, language: string} {
    const activeLang = this.transloco.getActiveLang();
    const activeLanguage = languages.find(lang => lang.code === activeLang);
    if (!activeLanguage) {
      throw new Error(`No language for ${activeLanguage}`);
    }

    return activeLanguage
  }

  changeLanguage(lang: string) {
    this.transloco.setActiveLang(lang);
    localStorage.setItem('defaultLang', lang);
  }
}
