import {Injectable} from '@angular/core';
import {AuthStore} from "@features/auth/framework/services/auth-store.service";
import {AuthApiService} from "../../infrastructure/auth-api.service";
import {firstValueFrom} from "rxjs";
import {ProfileStoreService} from "@features/profile/framework/profile-store.service";
import {Router} from "@angular/router";
import {RedirectionService} from "@features/auth/framework/services/redirection.service";
import {loginType} from "@features/auth/domain/login-type";

@Injectable({
  providedIn: 'root'
})
export class LogoutService {

  constructor(
    private authStore: AuthStore,
    private authApiService: AuthApiService,
    private profileStore: ProfileStoreService,
    private router: Router,
    private redirectionService: RedirectionService
  ) {
  }

  async run() {
    const loginType: loginType = this.authStore.snapshotOnly(state => state.loginType) || 'guest';
    const redirectionUrl = this.redirectionService.getLoggedOutUrlFor(loginType);
    try {
      const refreshToken = this.authStore.refreshToken();
      if (!refreshToken) {
        await this.router.navigateByUrl(redirectionUrl);
        return;
      }
      await firstValueFrom(this.authApiService.logout(refreshToken));
    } finally {
      this.authStore.logout();
      this.profileStore.resetDefaults();
      await this.router.navigateByUrl(redirectionUrl);
    }

  }
}
