import { Injectable } from '@angular/core';
import {AuthStore} from "@features/auth/framework/services/auth-store.service";
import {Router, UrlTree} from "@angular/router";
import {loginType} from "@features/auth/domain/login-type";

@Injectable({
  providedIn: 'root'
})
export class RedirectionService {

  constructor(private router: Router, private authStore: AuthStore) { }

  getLoggedInUrlFor(loginType: loginType): UrlTree {
    switch (loginType) {
      case 'admin':
        return this.router.parseUrl('/admin');
      case 'host':
        return this.router.parseUrl('/host');
      case 'guest':
        return this.router.parseUrl('/guest');
      default:
        return this.router.parseUrl('/guest');
    }
  }

  getLoggedOutUrlFor(loginType: loginType): UrlTree {
    switch (loginType) {
      case 'admin':
        return this.router.parseUrl('/admin/auth');
      case 'host':
        return this.router.parseUrl('/host/auth');
      default:
        return this.router.parseUrl('/guest/auth');
    }
  }
}
